//Table of Content

/*
1.0 Material variables

2.0 Mixins

3.0 Core
   3.1 Header
   3.2 Horizontal Menu
   3.3 Preloader
   3.4 Scaffolding
   3.5 Sidebar
   3.6 Typography

4.0 Material Theme
   4.1 Button
   4.2 Dialog
   4.3 List
   4.4 Select
   4.5 Tabs

5.0 Component
   5.1 About
   5.2 Calendar
   5.3 Chat
   5.4 Comingsoon
   5.5 Courses
   5.6 Dark
   5.7 Dash Sections
   5.8 E commerce
   5.9 Editor
   5.10 Faq
   5.11 Feedback
   5.12 Media
   5.13 Pricing
   5.14 Search Page
   5.15 Seesion
   5.16 Sidebar Option
   5.17 Socail
   5.17 Table
   5.17 Taskboard
   5.17 Timeline
   5.17 Tour
   5.17 User
   5.18 Wheather
   
6.0 Utilities
   6.1 Base
   6.2 Bg
   6.3 Font Text
   6.4 Rtl
   6.5 Spaces

*/

@import "material.variables";
@import "mixins/bi-app/bi-app-ltr";
@import "app.variables";
@import "mixins";
@import "core";
@import "material";
@import "components";

@import "utilities/utilities";



