/*==========|Ecommerce|===========*/
.ecommerce-wrap, .product-list-wrap{
   &.list-wrap, &.list-wrapper{
      .product-item{
         .button-wrap {
            position: absolute;
            top: 15px;
            right: 21px;
         }
      }
      .image-wrap,.product-item{
         position: relative;
      }
      .image-wrap{
         margin-right: 20px;
         display: inline-block;
      }
      .list-icon{
         opacity: 0.5;
         cursor: pointer;
      }
   }   
   .new-tag{
      position: absolute;
      right: 4px;
      top: 4px;
      background-color: $my-success;
      color: $light-color;
      padding: 3px 10px;
      border-radius: 15px;
      font-size: 0.75rem;
   }
   .app-dark{
      background-color: $my-dark-success;
   }
   &.grid-wrap{
      .grid-icon{
         opacity: 0.5;
         cursor: pointer;
      }
   }
   .filter-wrap{
      .mat-form-field-appearance-legacy .mat-form-field-underline{
         background-color: transparent;
      }
      .mat-form-field-infix,.mat-form-field-appearance-legacy .mat-form-field-wrapper{
         padding:0;
         width: 160px;
      }
   }
}
.checkout-wrap .billing-ship-content{
   white-space: normal;
}
.checkout-wrap,.cart-list-wrap{
   td.mat-cell, td.mat-footer-cell, th.mat-header-cell{
      padding: 5px 14px;
   }
}
.add-card-wrapper{
   td.mat-cell, td.mat-footer-cell, th.mat-header-cell{
      padding-left: 14px;
      padding-right: 14px;
   }
}
@media(max-width:599px){
   .ecommerce-wrap.list-wrap, div.product-list-wrap{
      .product-item{
         text-align: center;
         .image-wrap {
            margin-right: 0;
            display: inline-block !important;  
            margin-bottom:1rem;
         }
      }
      .product-item .content-wrap{
         margin-bottom:1rem;
         .button-wrap {
            position: static;
         }
      }   
   }
   div.product-list-wrap{
      .product-item{
         padding:0.4rem;
      }
   }
}
//Sidebar
.ecommerce-wrap{
   .widgets-sidebar-wrap{
      .ais-Panel-header {
         font-size: .95rem;
         border: 0;
         letter-spacing: 0.7px;
      }
      .search-wrap{
         .ais-SearchBox-input {
            height: 45px;
            padding-left: 32px;        
         }
         .ais-SearchBox-submit {
            left: .5rem;        
         }
      }
      .ais-RefinementList {
            .ais-RefinementList-labelText {
               width: calc(100% - 90px);
            }
            .ais-RefinementList-checkbox,.ais-RefinementList-labelText{
               display: inline-block;
               vertical-align: text-top;
            }
            .ais-RefinementList-label {
               margin: 0;
               padding: 0.5rem;
               display: block;
               cursor: pointer;
               .ais-RefinementList-checkbox {
                  margin: 0.25rem 0.625rem 0 0;
                  height: 1rem;
                  width: 1rem;
                  cursor: pointer;
               }
               .ais-RefinementList__value {
                  width: 80%;
                  font-size: 80%;
               }
               .ais-RefinementList-count {
                  float: right;
                  padding: 0.1rem 0.4rem;
                  font-size: 12px;
                  color: $light-color;
                  background-color: $my-primary;
                  border-radius: $border-radius-base;
                  margin-top: 2px;
               }
            }
      }
      .rating-wrap {
         .ais-RatingMenu-list {
            .ais-RatingMenu-link {
               margin-bottom: 0.6rem;
               .ais-RatingMenu-starIcon {
                  color: $my-warn !important;
                  width: 22px;
                  height: 22px;
               }
               .ais-RatingMenu-count {
                  font-size: 0.875rem;
               }
            }
         }
      }
      .clear-filter{
         .ais-ClearRefinements-button {
            width: 100%;
            padding: 0.75rem;
            background: $my-primary !important;
            color: $light-color;
         }
      }
      .price-wrap{
         .noUi-value {
            font-size: 0.9rem;
         }
         .noUi-tooltip{
            font-size: 0.8rem;
         }
         .noUi-connect{
            background:$my-primary;
         }
         .noUi-horizontal .noUi-tooltip{
            left: 40%;
         }
      }
   }
}
//Pagination
.ecom-pagination-wrap{
   .ais-Pagination-list{
      margin:0 1rem;
      justify-content: start;
      .ais-Pagination-link{
         border: 0;
         color: $font-color;
         background: transparent !important;
      }
   }
   .ais-Pagination-item--selected .ais-Pagination-link:focus, .ais-Pagination-item--selected .ais-Pagination-link:hover,.ais-Pagination-item--selected .ais-Pagination-link{
      color: $my-primary;
   }
}

//Cart Wrap
.cart-list-wrap .mat-card-content{
   max-height: 592px;
   overflow: hidden; 
   overflow-y: auto;
   .quantity-wrap{
      .qty-wrap, button{
         width: 7rem; 
      }
      .qty-wrap{
         border: 1px solid $my-primary;
         border-radius: 4px;
         padding: .25rem .5rem;      
      }
   }
}
.checkout-wrap{
   .mat-select-trigger {
      padding-bottom: 0px;
      font-size: 0.95rem;
      height: auto;
   }
   .maetrial-icons{
      font-size: 1.2rem;
      vertical-align: text-bottom;
   }
   .total-wrap{
      padding: 24px 24px 0;
   }
}

.video-popup .cdk-overlay-pane{
   width: 520px;
   .mat-form-field{
      width: 100%;
   } 
   mat-dialog-container{
      padding: 0;
      background-color: transparent;
      box-shadow: none;
   }
   iframe{
      min-height: 420px;
      width: 100%;
   }
}

.product-detail-page{
   .no-style li{
      margin-bottom:0.4rem;
   }
}

// products

body {
   .mat-card {
      .mat-card-image {
         max-width: inherit;
         border-bottom: 1px solid #eee;
      }     
      &.product-card{
         padding-top:1.5rem;
         padding-bottom:1.5rem;
      } 
   }
   .img-zoom-container{
      position:relative !important;
      #myresult {
         display: none;
      }
   }     
   .product-detail-page{
      .mat-mini-fab{ 
         height:2.5rem;
         width:2.5rem;
         i{
            padding:0;
            margin-right:0;
            line-height:2.5rem;
         }
      }   
   }   
}
.product-content,.product-item .content-wrap{
   .category-wrap{
      margin-bottom:0.5rem;
   }
   .text-line{
      margin-right:0.625rem;
   }
}
.m-icon {
   position: absolute;
   top: 55px;
   right: 20px;
   transition: all 0.3s ease-in;
   i{
      font-size:16px;
      margin-right:0px;
   }
   .mat-fab{
      width:30px;
      height:30px;
      line-height:30px;
      .mat-button-wrapper{
         padding:5px 0;
         line-height:20px;
      }   
   }
}
.m-icon.shop-cart{
   top:25px;
   .mat-fab{
      width:40px;
      height:40px;
      line-height:40px;   
   }
}
.wishlist-icon{
   position: absolute;
   top: 15px;
   right: 010px;
   z-index: 99;
   cursor: pointer;
   width: 50px;
   height: 50px;
   transition: all 0.3s ease-in 0s;
   text-align: center;
   line-height: 50px;
   a {
      color: rgba(0,0,0,0.4);
      &:hover{
         color:rgba(0, 0, 0, 0.7);
      }
   }
  
}
 


.wishlist-icon.wishlist-active a{
  color:rgba(0,0,0,1);
}

// product edit 

//edit product

.col-gap > div {
   padding-left: 1rem;
   padding-right: 1rem;
   margin-bottom: 1rem;
   margin-top: 1rem;
}    
.edit-icon,.add-icon{ 
   position: relative;
   &::after{
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background:rgba(0,0,0,0.75);
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;
      content: "";
   }
   &:hover::after{
      opacity: 1;
      visibility: visible;
   }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   .edit-icon .edit-btn,.add-icon .add-btn{
      right:auto;
      left:36px;
   }/* IE */
}
.edit-icon .edit-btn,.add-icon .add-btn{
   position: absolute;
   top: calc(50% - .8rem);
   left:0;
   right: 0;
   margin: 0 auto;
   border:0;
   border-radius: 100%;
   width: 20px;
   height: 20px;
   padding: 0;
   z-index: 3;
   &:hover{
      background-color:$light-color;
   }
   .mat-icon{
      color: rgb(40, 53, 147);
      width:16px;
      height:16px;
      font-size:16px;
   }
   label{
      vertical-align: middle;
      display: inline-block;         
      cursor: pointer;
   }
}
.edit-text,.add-text{
   position: relative;
   &::after {
      font-feature-settings: 'liga';
      font-family: 'Material Icons';
      content: "edit";
      -webkit-font-feature-settings: 'liga';
      position: absolute;
      top: 2px;      
      left: -25px;      
      font-size: 0.8em;      
      color: rgb(40, 53, 147);
   }
   .mat-form-field-underline{
      bottom:-8px;
   }
}
.add-text::after{
   content: "add";
}
.edit-check,.add-check{
   .mat-form-field-wrapper,.mat-form-field-infix{
      padding:0;
      border:0;
   }
}

@media (max-width:991px){
   .edit-icon .edit-btn{   
      width: 18px;
      height: 18px;      
      // top: calc(50% - 0.8rem);
      // left: calc(50% - 0.8rem);
      .mat-icon{
         width: 14px;
         height: 14px;
         font-size: 14px;
      }
   }
}

@media(max-width:959px){
   .edit-text{
      padding-left: 0.8rem;       
      &::after{
         left: -12px;
         font-size: 0.8em;
      } 
   }
   .add-text{
      &::after{
         left:-20px;
      }
   }
   .final-receipt-page{
      .user-content-wrapper{
         padding:0px;
         .mat-card{
            margin:0px;
         }
      }
   }
   .product-detail-page{
      .col-pad{
         padding:0px;
      }
   }
   .product-detail-content{
      margin-top:3rem;
   }
}


//shop   
.product-icon{
   i{
      cursor: pointer;
   }
   .active{
      opacity: 0.5;
   }
}
   




