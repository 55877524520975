@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import '~@angular/material/theming';
@include mat-core();

$primary: mat-palette($mat-blue,600);
$accent: mat-palette($mat-blue,100);
$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);

// custom
html, body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  margin: 0; height: 100%; overflow: hidden
}
 


$primary-color: mat-color($primary);
$background-color: rgb(237, 237, 237);
$secondary-background-color: #f8f8f8;
