/*========|User Management List|==========*/

.user-manage-list {
	table td.mat-cell, th.mat-header-cell {
		padding:0.7rem;
	}
	th.mat-header-cell:first-of-type {
   	padding-left: 0.625rem;
	}
	.rounded {
		width:10px;
		height:10px;
		border-radius:100%;
   }
   .status-icon{
      margin-top: 4px;
   }
   .status-text{
      width: calc(100% - 10px);
   }
	.mat-standard-chip {
		border-radius: 5px;
	    min-height: 22px;
	    font-size: 12px;

	}
	.table-responsive {
		overflow-x: auto;
    	overflow-y: hidden;
    	width: 100%;
   }
   .user-list-image {	
   	width:50px;
   	height:50px;
   }
   .mat-icon-button {
   	width:2.125rem;
   	height:2.125rem;
   	line-height:30px;
   } 	
}

.popup-card-width.add-new-user{
   width: 460px;
}

.cursor-pointer {
    cursor: pointer;
}
/*User Grid LisT*/

.user-grid-list-wrap {
	.grid-list-card {
		&:hover .img-overlay {
			transition: all 0.5s ease-in-out;
         transform: translateX(0px);
         opacity: 1;
         visibility: visible;
		}
		.img-overlay {
			position:absolute;
			top:18px;
			left:0;
			right:0;
			transform: translateX(224px);
         transition: all 0.5s ease-in-out;
         opacity: 0;
         visibility: hidden;
			.social-icon-wrapper {
				padding:6px 10px;
				a {
					padding-right:7px;
					font-size: 16px;
					text-align:center;
					&:last-child {
						padding-right:0px;
					}
				}
			}
		}
	}
	.mat-card-content {
		margin-bottom:0px;
		.mat-button-wrapper {
			display:flex;
			align-items:center;
			justify-content:center;
		}
		.mat-icon {
			font-size:16px;
			text-align:center;
		}
	}
	.img-thumbnail {
		margin-top:-20px;
		display:inline-block;
		border:2px solid $light-color;
		border-radius:100%;
		z-index:1;
	}
	.mat-card-actions {
		margin-left:0px;
		margin-right:0px;
	}
}