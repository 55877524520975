/*==========Coming Soon========*/

.comingsoon-wrap {
   position:relative;
   background-image: url(../../../assets/img/comingsoon-1920x1234.jpg);
   background-size:cover;
   z-index:1;
   min-height:100%;
   height: 100vh;
   .card-content .card-title span{
      font-size:3rem;
      letter-spacing:2px;
      font-weight:$font-weight-medium;
   }
   .overlay {
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background: rgba(0,0,0,0.7);
      z-index:-1;
   }
}